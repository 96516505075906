<template>
  <div class="slide">
    <div class="header d-flex w-100">
      <div class="slide-header order" v-b-toggle="`collapse-${order}`">
        {{ order }}
      </div>
      <div class="slide-header name" v-b-toggle="`collapse-${order}`">
        {{ name }} ({{ id }})
        <span
            v-if="isSaving && !valueIsSaved"
            class="pl-2 mb-0"
            style="color: #5cb85c"
        >{{ $t('button.saving') }}</span>
        <span
            v-if="isSaving && valueIsSaved"
            class="pl-2 mb-0"
            style="color: #5cb85c"
        >{{ $t('button.saved') }}</span>
      </div>
      <div class="dot-menu d-flex py-0">
        <div class="pr-1">
          <b-dropdown
            v-if="hasWriteAccess"
            toggle-class="dot-button"
            no-caret
            dropup
            no-flip
            right
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
            </template>
            <b-dropdown-item
              @click="$emit('open-rename-modal', id, name)"
            >
              {{ $t('dropdowns.rename') }}
              <b-icon icon="pencil-square" aria-hidden="true" scale=".8"></b-icon>
            </b-dropdown-item>
            <b-dropdown-item
              @click="$emit('delete-contact', id, name)"
            >
              {{ $t('dropdowns.delete') }}
              <b-icon icon="trash" aria-hidden="true" scale=".8"></b-icon>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="contacts-header-arrows d-flex" v-b-toggle="`collapse-${order}`">
          <b-icon
            v-if="!isCollapseOpen"
            icon="chevron-right"
            class="top-4 position-relative"
            style="color: #fff"
            aria-hidden="true"
            scale="1.2"
          ></b-icon>
          <b-icon
            v-else
            icon="chevron-down"
            class="top-4 position-relative"
            style="color: #fff"
            aria-hidden="true"
            scale="1.2"
          ></b-icon>
        </div>
      </div>
    </div>
    <b-collapse
        :id="`collapse-${order}`"
        @show="isCollapseOpen = true"
        @hidden="isCollapseOpen = false"
    >
      <div v-if="isCollapseOpen">
        <b-row>
          <b-col
            v-for="(fieldData, index) in valueData"
            cols="12"
            :key="index"
            class="mb-3"
            :class="`${index === 0 ? 'pt-3' : ''}`"
          >
            <component
                :is="fieldData.type"
                :id="fieldData.id"
                :name="fieldData.name"
                :value="fieldData.value"
                :hasWriteAccess="hasWriteAccess"
                @update="updateValue($event, fieldData.id)"
            />
          </b-col>
        </b-row>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'ContactRowSlide',
  components: {
    MediaField: () => import('@/components/settings/personalization/fields/MediaField.vue'),
    NumberField: () => import('@/components/settings/personalization/fields/NumberField.vue'),
    RichTextField: () => import('@/components/settings/personalization/fields/RichTextField.vue'),
    TextField: () => import('@/components/settings/personalization/fields/TextField.vue'),
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    order: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    schema: {
      type: Array,
      default: null,
    },
    values: {
      type: Object,
      default: null,
    },
    valueIsSaved: {
      type: Boolean,
      default: false,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      isCollapseOpen: false, // used for lazy loading
      settingsData: null,
      valuesFromDB: null,
      isSaving: false,
      intervalForSave: false,
    };
  },
  created() {
    this.settingsData = { ...this.schema };
    this.valuesFromDB = { ...this.values };
  },
  computed: {
    valueData: {
      cache: false,
      get() {
        if (this.schema && this.schema.length > 0) {
          // build the data object with the schema information
          const valueDataArray = [];
          this.schema.forEach((schemaItem) => {
            let fieldValueFromDatabase = null;
            if (this.values && this.values[schemaItem.id]) {
              fieldValueFromDatabase = this.values[schemaItem.id];
            }
            valueDataArray.push({
              name: schemaItem.name,
              id: schemaItem.id,
              type: schemaItem.type,
              order: schemaItem.order,
              value: fieldValueFromDatabase,
            });
          });
          return valueDataArray.sort((a, b) => a.order - b.order);
        }
        return [];
      },
    },
  },
  watch: {
    configData(val) {
      this.settingsData = { ...val };
    },
    valueIsSaved(newVal) {
      clearInterval(this.intervalForSave);
      if (this.isSaving && newVal) {
        this.intervalForSave = setInterval(() => {
          this.isSaving = false;
          this.$emit('value-save-status-completed');
        }, 1000);
      }
    },
  },
  mounted() {
    this.$emit('row-rendered');
  },
  methods: {
    updateValue(value, id) {
      this.isSaving = true;
      this.$emit('update-field-value', value, id, this.id);
    },
    updateSettingValue(value, handle) {
      this.settingsData[handle] = value;
      const emitSettingsObject = { ...this.settingsData };
      this.$emit('update-setting-row', emitSettingsObject, this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  margin-bottom: 8px;
  background-color: #fff;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

  .header {
    background-color: #53575B;
    > div {
      flex-basis: 30px;
      text-align: center;
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .order {
      flex-basis: 60px;
    }

    .name {
      flex-grow: 2;
      text-align: left;
    }

    .dot-menu {
      flex-basis: 40px;
      > div:first-child {
        padding-top: 7px;
        padding-bottom: 7px;
      }
      .contacts-header-arrows {
        background-color: $webmag-grey;
        width: 50px;
        align-items: center;
        justify-content: center;
        svg {
        }
      }
      svg {
        cursor: pointer;
      }
    }
  }

  .new-value-box {
    background-color: #EFEFEF;
    h4, p {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 12px;
      color: #64696C;
    }
    h4 {
      font-weight: 600;
    }
  }

  /deep/ .collapse {
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    h2, h3 {
      color: #B9B9B9;
    }
  }
}

/deep/ .dropdown .btn-secondary {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 0;
  line-height: 10px;

  &:hover {
    color: #fff;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}
</style>
