var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slide" },
    [
      _c("div", { staticClass: "header d-flex w-100" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: `collapse-${_vm.order}`,
                expression: "`collapse-${order}`"
              }
            ],
            staticClass: "slide-header order"
          },
          [_vm._v(" " + _vm._s(_vm.order) + " ")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: `collapse-${_vm.order}`,
                expression: "`collapse-${order}`"
              }
            ],
            staticClass: "slide-header name"
          },
          [
            _vm._v(" " + _vm._s(_vm.name) + " (" + _vm._s(_vm.id) + ") "),
            _vm.isSaving && !_vm.valueIsSaved
              ? _c(
                  "span",
                  {
                    staticClass: "pl-2 mb-0",
                    staticStyle: { color: "#5cb85c" }
                  },
                  [_vm._v(_vm._s(_vm.$t("button.saving")))]
                )
              : _vm._e(),
            _vm.isSaving && _vm.valueIsSaved
              ? _c(
                  "span",
                  {
                    staticClass: "pl-2 mb-0",
                    staticStyle: { color: "#5cb85c" }
                  },
                  [_vm._v(_vm._s(_vm.$t("button.saved")))]
                )
              : _vm._e()
          ]
        ),
        _c("div", { staticClass: "dot-menu d-flex py-0" }, [
          _c(
            "div",
            { staticClass: "pr-1" },
            [
              _vm.hasWriteAccess
                ? _c(
                    "b-dropdown",
                    {
                      attrs: {
                        "toggle-class": "dot-button",
                        "no-caret": "",
                        dropup: "",
                        "no-flip": "",
                        right: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "three-dots-vertical",
                                    "aria-hidden": "true",
                                    scale: ".8"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2405648877
                      )
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "open-rename-modal",
                                _vm.id,
                                _vm.name
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("dropdowns.rename")) + " "
                          ),
                          _c("b-icon", {
                            attrs: {
                              icon: "pencil-square",
                              "aria-hidden": "true",
                              scale: ".8"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "delete-contact",
                                _vm.id,
                                _vm.name
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("dropdowns.delete")) + " "
                          ),
                          _c("b-icon", {
                            attrs: {
                              icon: "trash",
                              "aria-hidden": "true",
                              scale: ".8"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: `collapse-${_vm.order}`,
                  expression: "`collapse-${order}`"
                }
              ],
              staticClass: "contacts-header-arrows d-flex"
            },
            [
              !_vm.isCollapseOpen
                ? _c("b-icon", {
                    staticClass: "top-4 position-relative",
                    staticStyle: { color: "#fff" },
                    attrs: {
                      icon: "chevron-right",
                      "aria-hidden": "true",
                      scale: "1.2"
                    }
                  })
                : _c("b-icon", {
                    staticClass: "top-4 position-relative",
                    staticStyle: { color: "#fff" },
                    attrs: {
                      icon: "chevron-down",
                      "aria-hidden": "true",
                      scale: "1.2"
                    }
                  })
            ],
            1
          )
        ])
      ]),
      _c(
        "b-collapse",
        {
          attrs: { id: `collapse-${_vm.order}` },
          on: {
            show: function($event) {
              _vm.isCollapseOpen = true
            },
            hidden: function($event) {
              _vm.isCollapseOpen = false
            }
          }
        },
        [
          _vm.isCollapseOpen
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    _vm._l(_vm.valueData, function(fieldData, index) {
                      return _c(
                        "b-col",
                        {
                          key: index,
                          staticClass: "mb-3",
                          class: `${index === 0 ? "pt-3" : ""}`,
                          attrs: { cols: "12" }
                        },
                        [
                          _c(fieldData.type, {
                            tag: "component",
                            attrs: {
                              id: fieldData.id,
                              name: fieldData.name,
                              value: fieldData.value,
                              hasWriteAccess: _vm.hasWriteAccess
                            },
                            on: {
                              update: function($event) {
                                return _vm.updateValue($event, fieldData.id)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }